<template>
  <div class="education-wrapper">
    <div class="education-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/admin/decorationdata' }">店铺装修管理</el-breadcrumb-item>
        <el-breadcrumb-item>店铺注册</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation">
        <div class="category">
          <span class="label">行业分类</span>
          <el-select v-model="categoryId" placeholder="请选择行业分类" clearable @change="changeCategory">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="btn-group">
<!--          <send-btn :com="this" :request="sendDecorateAPI"></send-btn>-->
          <el-button class="btn-blue" @click="addBtn">创建店铺注册数据</el-button>
        </div>
      </div>
    </div>
    <el-table :data="decorateList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
              :cell-style="{fontSize: '14px',color: '#343441'}" ref="elTable">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column align="center" label="店铺注册名称">
        <template slot-scope="scope">
          <p class="text-overflow-2" :title="scope.row.store_registration_name">{{ scope.row.store_registration_name}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="category" align="center" label="行业分类"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link :underline="false" class="link-blue" @click="copyTemplate(scope.row)">复制</el-link>
          <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="deleteBtn(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pager-center" style="margin-top: 10px;"
                   :current-page="decorationPages.currentPageNum"
                   :page-size="decorationPages.eachPageNum"
                   :total="decorationPages.total"
                   layout="prev, pager, next, jumper"
                   @current-change="decorationCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import SendBtn from "../../../components/plugs/SendTemplateBtn.vue"
import { copyShopDecorateTemplateAPI, sendDecorateAPI } from "../../../utils/apis"
export default {
  data() {
    return {
      decorateList: [],
      decorationPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      sendDecorateAPI: sendDecorateAPI,
      categoryList: [],
      categoryId: void 0
    }
  },
  components: {
    SendBtn
  },
  mounted() {
    this.init()
  },
  watch: {
    $route(to, from) {
      if (to.name === 'adminRegisterData') {
        this.init()
      }
    }
  },
  methods: {
    changeCategory() {
      this.decorationPages.currentPageNum = 1
      this.getDecorateList();
    },
    init() {
      this.getCategory();
      this.getDecorateList();
    },
    getCategory() {
      this.$http.axiosGet(this.$api.categoryList, (res) => {
        if (res.code === 200) {
          this.categoryList = res.data
        } else {
          this.$message.warning(res.msg)
        }
      }, (err) => {
        console.log(err)
      })
    },
    async copyTemplate(item) {
      const valid = await this.$common.copyTemplatePack(copyShopDecorateTemplateAPI, { id: item.id });
      valid && this.getDecorateList();
    },
    getDecorateList() {
      let params = {
        type: 1,
        page: this.decorationPages.currentPageNum,
        limit: this.decorationPages.eachPageNum,
      }
      if (this.categoryId) {
        params.category_id = this.categoryId
      }
      this.$http.axiosGetBy(this.$api.storeSettingsList, params, (res) => {
        if (res.code === 200) {
          this.decorateList = res.data.list
          this.decorationPages.total = res.data.total
          if (this.decorationPages.total !== 0 && this.decorateList.length === 0) {
            this.decorationPages.currentPageNum--;
            this.getDecorateList();
          }
        } else {
          this.$message.warning(res.msg)
        }
      }, (err) => {
        console.log(err)
      })
    },
    decorationCurrentChange(val) {
      this.decorationPages.currentPageNum = val
      this.getDecorateList();
    },
    addBtn() {
      this.$router.push('/admin/registerCreate')
    },
    editBtn(row) {
      this.$router.push({
        path: '/admin/registerCreate',
        query: {
          id: row.id
        }
      })
    },
    deleteBtn(id) {
      this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'btn-blue',
        type: 'warning',
      }).then(() => {
        this.$http.axiosPost(this.$api.storeSettingsDel, {id: id}, (res) => {
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.getDecorateList()
          } else {
            this.$message.warning(res.msg)
          }
        }, (err) => {
          console.log(err)
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      });
    }
  }
}
</script>

<style scoped lang="scss">
.education-wrapper {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .education-header {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    .el-breadcrumb {
      line-height: 40px;
    }
    .operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .category .label{
        margin-right: 10px;
      }
    }
  }
}
.el-link + .el-link {
  margin-left: 10px;
}
/deep/ p {
  margin: 0;
}
/deep/ .el-table__row .is-checked .el-checkbox__inner,
/deep/ .has-gutter .el-checkbox .is-indeterminate .el-checkbox__inner,
/deep/ .has-gutter .el-checkbox .is-checked .el-checkbox__inner {
  background-color: #1122d8;
  border-color: #1122d8;
}
</style>